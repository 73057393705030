'use client';

import { useInfiniteQuery } from '@tanstack/react-query';

import { BOOKMARK_TYPE } from '@/api';
import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { policyInfoQueryOptions } from '@/query-factory';
import { useAuthStore } from '@/stores/AuthStore';

import { useBookmarkMutation } from '../_hooks/useBookmarkMutation';

import { KbNewsSmallCard } from './KbNewsSmallCard';
import { MainArticleWrap } from './MainArticleWrap';
import styles from './NewsSmallCardPolicyInfoList.module.scss';

export const NewsSmallCardPolicyInfoList = () => {
  const isLogin = useAuthStore((state) => state.isLogin);
  const { postBookmark } = useBookmarkMutation({
    queryKey: policyInfoQueryOptions.list({ isLogin }).queryKey,
  });

  const router = useCustomRouter();
  const { data } = useInfiniteQuery(policyInfoQueryOptions.list({ isLogin }));

  return (
    <MainArticleWrap
      loadMoreButtonType="link"
      loadMoreLink={ROUTES.POLICY_INFO_LIST.pathname}
      loadMoreText="정책소식 더보기"
      title="정책소식"
    >
      <ul className={styles['news-small-card-policy-info-list']}>
        {!data?.[0].policyInfoList ? (
          <li className={styles['title-skeleton-container']}>
            <KbNewsSmallCard isLoading />
            <KbNewsSmallCard isLoading />
            <KbNewsSmallCard isLoading />
          </li>
        ) : (
          <>
            {data[0].policyInfoList.splice(0, 3).map((policy) => {
              return (
                <li key={policy.best_contents_id}>
                  <KbNewsSmallCard
                    dateText={`${policy.start_at} ~ ${policy.end_at}`}
                    href={ROUTES.POLICY_INFO_DETAIL.pathname({
                      idIdx: policy.best_contents_id_idx,
                    })}
                    isBookmarked={policy.wishlist_yn}
                    thumbnail={policy.file_url}
                    title={policy.title}
                    onClickBookmark={() =>
                      isLogin
                        ? postBookmark({
                            contentId: policy.best_contents_id,
                            isBookmarked: policy.wishlist_yn,
                            bookmarkType: BOOKMARK_TYPE.POLICY_INFO,
                          })
                        : router.push(ROUTES.LOGIN.pathname)
                    }
                  />
                </li>
              );
            })}
          </>
        )}
      </ul>
    </MainArticleWrap>
  );
};
