// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
'use client';

import { ChevronRight } from 'react-feather';

import Link from 'next/link';

import { BOOKMARK_TYPE } from '@/api';
import { Skeleton } from '@/components/server';
import { ROUTES } from '@/constants';
import { queryKeys } from '@/constants/query-keys';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useGeolocation } from '@/hooks/useGeolocation';
import { useGetHometownListQuery } from '@/hooks/useGetHometownListQuery';
import { useAuthStore } from '@/stores/AuthStore';

import { useBookmarkMutation } from '../_hooks/useBookmarkMutation';

import { KbNewsSmallCard } from './KbNewsSmallCard';
import { MainArticleWrap } from './MainArticleWrap';
import styles from './NewsSmallCardPolicyInfoList.module.scss';

export const NewsSmallCardHometownNewsList = () => {
  const { location } = useGeolocation();

  const { data } = useGetHometownListQuery({
    codeRegion: location.codeRegion,
    codeSubRegion: location.codeSubRegion,
  });
  const { postBookmark } = useBookmarkMutation({
    queryKey: [
      queryKeys.hometownNewsList,
      location.codeRegion,
      location.codeSubRegion,
    ],
  });
  const isLogin = useAuthStore((state) => state.isLogin);
  const router = useCustomRouter();

  return (
    <MainArticleWrap
      loadMoreButtonType="link"
      loadMoreLink={ROUTES.HOMETOWN_NEWS.pathname}
      loadMoreText="동네소식 더보기"
      title="동네소식"
    >
      <header className={styles['location-top']}>
        {!data?.pages[0].contents ? (
          <Skeleton className={styles['hometown-skeleton-header']} />
        ) : (
          <Link href={ROUTES.HOMETOWN_NEWS_SEARCH.pathname}>
            {location.subRegion}
            <ChevronRight className={styles.rotate} />
          </Link>
        )}
      </header>

      <ul className={styles['news-small-card-policy-info-list']}>
        {!data?.pages[0]?.contents ? (
          <li className={styles['title-skeleton-container']}>
            <KbNewsSmallCard isLoading />
            <KbNewsSmallCard isLoading />
            <KbNewsSmallCard isLoading />
          </li>
        ) : (
          <>
            {data.pages[0].contents.slice(0, 3).map((news) => {
              return (
                <li key={news.id}>
                  <KbNewsSmallCard
                    {...news}
                    dateText={`${news.dateText} 작성`}
                    href={ROUTES.HOMETOWN_NEWS_DETAIL.pathname({
                      idIdx: news.idIdx,
                    })}
                    thumbnail={news.thumbnail}
                    onClickBookmark={() =>
                      isLogin
                        ? postBookmark({
                            contentId: news.id,
                            isBookmarked: news.isBookmarked,
                            bookmarkType: BOOKMARK_TYPE.HOMETOWN_NEWS,
                          })
                        : router.push(ROUTES.LOGIN.pathname)
                    }
                  />
                </li>
              );
            })}
          </>
        )}
      </ul>
    </MainArticleWrap>
  );
};
