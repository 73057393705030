import type { QueryKey } from '@tanstack/react-query';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AUTH_API } from '@/api';
import { useToast } from '@/hooks/useToast';

interface BookmarkProps {
  queryKey: QueryKey;
}

interface BookmarkMutationParams {
  contentId: number;
  isBookmarked: boolean;
  bookmarkType: string;
}

export const useBookmarkMutation = ({ queryKey }: BookmarkProps) => {
  const { toastError } = useToast();
  const queryClient = useQueryClient();

  const { isPaused, mutate } = useMutation({
    mutationFn: ({
      contentId,
      isBookmarked,
      bookmarkType,
    }: BookmarkMutationParams) => {
      const requestBody = {
        requestBody: {
          content_id: contentId,
          content_type_cd: bookmarkType,
        },
      };

      return isBookmarked
        ? AUTH_API.deleteBookmark(requestBody)
        : AUTH_API.postBookmark(requestBody);
    },
    onError: (error) => {
      toastError({
        error,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    isPaused,
    postBookmark: mutate,
  };
};
