'use client';

import { useShallow } from 'zustand/react/shallow';

import { BOOKMARK_TYPE, LIKE_TYPE } from '@/api';
import { useGetRecommendPolicyListQuery } from '@/app/_hooks/useGetRecommendPolicyListQuery';
import { ROUTES } from '@/constants';
import { queryKeys } from '@/constants/query-keys';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useLikeMutation } from '@/hooks/useLikeMutation';
import { useAuthStore } from '@/stores/AuthStore';

import { useBookmarkMutation } from '../_hooks/useBookmarkMutation';

import { KbRecommendNewsCard } from './KbRecommendNewsCard';
import { MainArticleWrap } from './MainArticleWrap';
import styles from './RecommendPolicyList.module.scss';

export const RecommendPolicyList = () => {
  const [isLogin, myInfo] = useAuthStore(
    useShallow((state) => [state.isLogin, state.myInfo]),
  );
  const { data } = useGetRecommendPolicyListQuery();
  const { postBookmark } = useBookmarkMutation({
    queryKey: [queryKeys.recommendPolicyList],
  });
  const { postLike } = useLikeMutation({
    queryKey: [queryKeys.recommendPolicyList],
  });
  const router = useCustomRouter();

  const age = myInfo?.age ? Math.floor(myInfo.age / 10) * 10 : undefined;

  return isLogin ? (
    <MainArticleWrap
      loadMoreButtonType="link"
      loadMoreLink={ROUTES.RECOMMEND_POLICY_LIST.pathname}
      loadMoreText="나의 맞춤정책 더보기"
      title={
        <header className={styles.header}>
          <p>웰로가 추천해 드려요!</p>
          <h2>
            {age ? `${age}대 ` : null}
            {myInfo?.nickName}님을 위한
            <br />
            맞춤 정책 <span> {data?.pages[0].totalCount ?? '-'}건</span>
          </h2>
        </header>
      }
    >
      <ul className={styles['recommend-policy-list']}>
        {!data?.pages[0].policyList ? (
          <li className={styles['title-skeleton-container']}>
            <KbRecommendNewsCard isLoading />
            <KbRecommendNewsCard isLoading />
            <KbRecommendNewsCard isLoading />
          </li>
        ) : (
          <>
            {data.pages[0].policyList
              ?.slice(0, 3)
              ?.map(({ id = 0, ...policy }) => (
                <li key={id}>
                  <KbRecommendNewsCard
                    agency={policy.agency}
                    agencyLogo={policy.agencyLogo}
                    commentCount={policy.commentCount}
                    dateText={policy.dueDateEnd}
                    dday={policy.dday}
                    href={ROUTES.POLICY_DETAIL.pathname({
                      idIdx: policy.idIdx,
                    })}
                    isBookmarked={policy.isWishlist}
                    isThumbUp={policy.isThumbUp}
                    policyName={policy.policyName}
                    supportBenefit={policy.supportBenefit}
                    thumbUpCount={policy.likeCount}
                    onClickBookmark={() =>
                      postBookmark({
                        contentId: id,
                        isBookmarked: policy.isWishlist,
                        bookmarkType: BOOKMARK_TYPE.POLICY,
                      })
                    }
                    onClickComment={() => {
                      router.push(
                        `${ROUTES.POLICY_DETAIL.pathname({
                          idIdx: policy.idIdx,
                        })}#comments`,
                      );
                    }}
                    onClickThumbUp={() =>
                      postLike({
                        contentId: id,
                        isLike: policy.isThumbUp,
                        likeType: LIKE_TYPE.POLICY,
                      })
                    }
                  />
                </li>
              ))}
          </>
        )}
      </ul>
    </MainArticleWrap>
  ) : (
    <></>
  );
};
