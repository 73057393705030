'use client';

import type { ListWelloPickDto } from '@generatedapi/auth';

import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide, type SwiperProps } from 'swiper/react';

import { ROUTES } from '@/constants';

import { MainArticleWrap } from './MainArticleWrap';
import { UserCard } from './UserCard';
import styles from './UserCardList.module.scss';

interface UserCardListProps {
  summary?: string;
  userCardList?: ListWelloPickDto[];
}

const SWIPER_PROPS = {
  grabCursor: true,
  effect: 'creative',
  slidesPerView: 2,
  spaceBetween: 0,
  breakpoints: {
    400: {
      slidesPerView: 3,
      spaceBetween: '10px',
    },
    300: {
      slidesPerView: 2,
      spaceBetween: '10px',
    },
  },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  modules: [Autoplay],
} satisfies SwiperProps;

export const UserCardList = ({ userCardList }: UserCardListProps) => {
  return (
    <MainArticleWrap
      loadMoreLink={ROUTES.MY_POLICY_FILTER.pathname}
      loadMoreText="맞춤 정보 입력하고, 내 혜택 찾아보기"
      title={
        <span className={styles.benefit}>
          웰로가 알려주는 추천 정책
          <p className={styles['small-title']}>
            이만큼의 혜택을 받을 수 있어요!
          </p>
        </span>
      }
    >
      <Swiper {...SWIPER_PROPS}>
        {userCardList?.map((card) => (
          <SwiperSlide key={card.title}>
            <UserCard card={card} />
          </SwiperSlide>
        ))}
      </Swiper>
    </MainArticleWrap>
  );
};
