import { ChevronRight } from 'react-feather';

import { cleanClassName } from '@wello-client/common/utils';
import Link from 'next/link';

import styles from './MainArticleWrap.module.scss';

interface MainArticleWrapProps {
  title: React.ReactNode;
  children: React.ReactNode;
  loadMoreLink?: string;
  loadMoreText?: string;
  loadMoreButtonType?: 'button' | 'link';
  className?: string;
}

export const MainArticleWrap = ({
  title,
  children,
  loadMoreLink,
  loadMoreText,
  className,
  loadMoreButtonType,
}: MainArticleWrapProps) => {
  const isLoadMoreLink = loadMoreButtonType === 'link';

  return (
    <article
      className={cleanClassName(`${styles['main-article']} ${className}`)}
    >
      <section className={styles['main-section']}>
        <h2 className={styles.title}>{title}</h2>
        {children}
      </section>
      {loadMoreLink ? (
        <Link
          className={
            isLoadMoreLink
              ? styles['load-more-link']
              : styles['load-more-button']
          }
          href={loadMoreLink}
        >
          {loadMoreText}
          {isLoadMoreLink ? <ChevronRight size="1.6rem" /> : null}
        </Link>
      ) : null}
    </article>
  );
};
