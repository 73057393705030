import Link from 'next/link';

import { LazyImage } from '@/components/client';
import {
  NewsCardFooter,
  type NewsCardFooterProps,
  Badge,
} from '@/components/server';
import { Skeleton } from '@/components/server';

import styles from './KbRecommendNewsCard.module.scss';

interface NewsCardProps extends NewsCardFooterProps {
  dday?: string;
  agencyLogo?: string;
  policyName?: string;
  agency?: string;
  dateText?: string;
  supportBenefit?: string;
  href?: string;
}

export const KbRecommendNewsCard = ({
  dday,
  agencyLogo,
  policyName,
  agency,
  dateText,
  supportBenefit,
  href = '#',
  isLoading,
  ...restProps
}: NewsCardProps) => {
  return (
    <article className={styles['policy-card']}>
      <Link className={styles['card-area']} href={href}>
        {isLoading ? (
          <Skeleton className={styles['badge-skeleton']} />
        ) : (
          <Badge color="red">{dday}</Badge>
        )}
        <div className={styles.link}>
          <LazyImage
            alt={`policy-card-${policyName}`}
            className={styles.thumbnail}
            isLoading={isLoading}
            src={agencyLogo}
          />
          {isLoading ? (
            <div className={styles['title-skeleton-container']}>
              <Skeleton className={styles['title-skeleton']} />
              <Skeleton className={styles['title-skeleton']} />
            </div>
          ) : (
            <h3 className={styles.title}>{policyName}</h3>
          )}
        </div>
        <div className={styles['policy-info']}>
          {isLoading ? (
            <>
              <Skeleton className={styles['date-text-skeleton']} />
              <Skeleton className={styles['date-text-skeleton']} />
              <Skeleton className={styles['date-text-skeleton']} />
            </>
          ) : (
            <>
              <span className={styles['text-ellipsis']}>{agency}</span>
              {dateText && (
                <span className={styles['text-ellipsis']}>{dateText}</span>
              )}
              {supportBenefit && (
                <span className={styles['text-ellipsis']}>
                  {supportBenefit}
                </span>
              )}
            </>
          )}
        </div>
      </Link>
      <NewsCardFooter
        {...restProps}
        className={styles.footer}
        isLoading={isLoading}
      />
    </article>
  );
};
